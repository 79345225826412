/*!***************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[1].use[1]!./node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[1].use[2]!./node_modules/resolve-url-loader/index.js??ruleSet[1].rules[5].rules[1].use[0]!./node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[5].rules[1].use[1]!./src/custom-theme.scss ***!
  \***************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************/
  .mat-badge-content {
    font-weight: 600;
    font-size: 12px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-badge-small .mat-badge-content {
    font-size: 9px;
  }
  
  .mat-badge-large .mat-badge-content {
    font-size: 24px;
  }
  
  .mat-h1, .mat-headline, .mat-typography h1 {
    font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
  }
  
  .mat-h2, .mat-title, .mat-typography h2 {
    font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
  }
  
  .mat-h3, .mat-subheading-2, .mat-typography h3 {
    font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
  }
  
  .mat-h4, .mat-subheading-1, .mat-typography h4 {
    font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
  }
  
  .mat-h5, .mat-typography h5 {
    font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
    margin: 0 0 12px;
  }
  
  .mat-h6, .mat-typography h6 {
    font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
    margin: 0 0 12px;
  }
  
  .mat-body-strong, .mat-body-2 {
    font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  
  .mat-body, .mat-body-1, .mat-typography {
    font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px;
  }
  
  .mat-small, .mat-caption {
    font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  
  .mat-display-4, .mat-typography .mat-display-4 {
    font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: -0.05em;
    margin: 0 0 56px;
  }
  
  .mat-display-3, .mat-typography .mat-display-3 {
    font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: -0.02em;
    margin: 0 0 64px;
  }
  
  .mat-display-2, .mat-typography .mat-display-2 {
    font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: -0.005em;
    margin: 0 0 64px;
  }
  
  .mat-display-1, .mat-typography .mat-display-1 {
    font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 64px;
  }
  
  .mat-bottom-sheet-container {
    font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  
  .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
  .mat-flat-button, .mat-fab, .mat-mini-fab {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  
  .mat-button-toggle {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-card {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-card-title {
    font-size: 24px;
    font-weight: 500;
  }
  
  .mat-card-header .mat-card-title {
    font-size: 20px;
  }
  
  .mat-card-subtitle,
  .mat-card-content {
    font-size: 14px;
  }
  
  .mat-checkbox {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 24px;
  }
  
  .mat-chip {
    font-size: 14px;
    font-weight: 500;
  }
  
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px;
  }
  
  .mat-table {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-header-cell {
    font-size: 12px;
    font-weight: 500;
  }
  
  .mat-cell, .mat-footer-cell {
    font-size: 14px;
  }
  
  .mat-calendar {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-calendar-body {
    font-size: 13px;
  }
  
  .mat-calendar-body-label,
  .mat-calendar-period-button {
    font-size: 14px;
    font-weight: 500;
  }
  
  .mat-calendar-table-header th {
    font-size: 11px;
    font-weight: 400;
  }
  
  .mat-dialog-title {
    font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  
  .mat-expansion-panel-header {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 15px;
    font-weight: 400;
  }
  
  .mat-expansion-panel-content {
    font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  
  .mat-form-field {
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  
  .mat-form-field-wrapper {
    padding-bottom: 1.34375em;
  }
  
  .mat-form-field-prefix .mat-icon,
  .mat-form-field-suffix .mat-icon {
    font-size: 150%;
    line-height: 1.125;
  }
  
  .mat-form-field-prefix .mat-icon-button,
  .mat-form-field-suffix .mat-icon-button {
    height: 1.5em;
    width: 1.5em;
  }
  
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125;
  }
  
  .mat-form-field-infix {
    padding: 0.5em 0;
    border-top: 0.84375em solid transparent;
  }
  
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.34375em) scale(0.75);
    width: 133.3333333333%;
  }
  
  .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.34374em) scale(0.75);
    width: 133.3333433333%;
  }
  
  .mat-form-field-label-wrapper {
    top: -0.84375em;
    padding-top: 0.84375em;
  }
  
  .mat-form-field-label {
    top: 1.34375em;
  }
  
  .mat-form-field-underline {
    bottom: 1.34375em;
  }
  
  .mat-form-field-subscript-wrapper {
    font-size: 75%;
    margin-top: 0.6666666667em;
    top: calc(100% - 1.7916666667em);
  }
  
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1.25em;
  }
  
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.4375em 0;
  }
  
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    -ms-transform: translateY(-1.28125em) scale(0.75);
    width: 133.3333333333%;
  }
  
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
    -ms-transform: translateY(-1.28124em) scale(0.75);
    width: 133.3333433333%;
  }
  
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
    -ms-transform: translateY(-1.28123em) scale(0.75);
    width: 133.3333533333%;
  }
  
  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 1.28125em;
  }
  
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 1.25em;
  }
  
  .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: 0.5416666667em;
    top: calc(100% - 1.6666666667em);
  }
  
  @media print {
    .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28122em) scale(0.75);
    }
    .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.28121em) scale(0.75);
    }
    .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.2812em) scale(0.75);
    }
  }
  
  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0.25em 0 0.75em 0;
  }
  
  .mat-form-field-appearance-fill .mat-form-field-label {
    top: 1.09375em;
    margin-top: -0.5em;
  }
  
  .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-0.59375em) scale(0.75);
    width: 133.3333333333%;
  }
  
  .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-0.59374em) scale(0.75);
    width: 133.3333433333%;
  }
  
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1em 0 1em 0;
  }
  
  .mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.84375em;
    margin-top: -0.25em;
  }
  
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.59375em) scale(0.75);
    width: 133.3333333333%;
  }
  
  .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.59374em) scale(0.75);
    width: 133.3333433333%;
  }
  
  .mat-grid-tile-header,
  .mat-grid-tile-footer {
    font-size: 14px;
  }
  
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
  }
  
  .mat-grid-tile-header .mat-line:nth-child(n+2),
  .mat-grid-tile-footer .mat-line:nth-child(n+2) {
    font-size: 12px;
  }
  
  input.mat-input-element {
    margin-top: -0.0625em;
  }
  
  .mat-menu-item {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  
  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
  }
  
  .mat-radio-button {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-select {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-select-trigger {
    height: 1.125em;
  }
  
  .mat-slide-toggle-content {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-slider-thumb-label-text {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 500;
  }
  
  .mat-stepper-vertical, .mat-stepper-horizontal {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-step-label {
    font-size: 14px;
    font-weight: 400;
  }
  
  .mat-step-sub-label-error {
    font-weight: normal;
  }
  
  .mat-step-label-error {
    font-size: 14px;
  }
  
  .mat-step-label-selected {
    font-size: 14px;
    font-weight: 500;
  }
  
  .mat-tab-group {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-tab-label, .mat-tab-link {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  
  .mat-toolbar,
  .mat-toolbar h1,
  .mat-toolbar h2,
  .mat-toolbar h3,
  .mat-toolbar h4,
  .mat-toolbar h5,
  .mat-toolbar h6 {
    font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0;
  }
  
  .mat-tooltip {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  
  .mat-tooltip-handset {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .mat-list-item {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-list-option {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-list-base .mat-list-item {
    font-size: 16px;
  }
  
  .mat-list-base .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
  }
  
  .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
    font-size: 14px;
  }
  
  .mat-list-base .mat-list-option {
    font-size: 16px;
  }
  
  .mat-list-base .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
  }
  
  .mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
    font-size: 14px;
  }
  
  .mat-list-base .mat-subheader {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  
  .mat-list-base[dense] .mat-list-item {
    font-size: 12px;
  }
  
  .mat-list-base[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
  }
  
  .mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
    font-size: 12px;
  }
  
  .mat-list-base[dense] .mat-list-option {
    font-size: 12px;
  }
  
  .mat-list-base[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
  }
  
  .mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
    font-size: 12px;
  }
  
  .mat-list-base[dense] .mat-subheader {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 500;
  }
  
  .mat-option {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
  }
  
  .mat-optgroup-label {
    font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  
  .mat-simple-snackbar {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
  }
  
  .mat-simple-snackbar-action {
    line-height: 1;
    font-family: inherit;
    font-size: inherit;
    font-weight: 500;
  }
  
  .mat-tree {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
  
  .mat-tree-node,
  .mat-nested-tree-node {
    font-weight: 400;
    font-size: 14px;
  }
  
  .mat-ripple {
    overflow: hidden;
    position: relative;
  }
  
  .mat-ripple:not(:empty) {
    transform: translateZ(0);
  }
  
  .mat-ripple.mat-ripple-unbounded {
    overflow: visible;
  }
  
  .mat-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale(0);
  }
  
  .cdk-high-contrast-active .mat-ripple-element {
    display: none;
  }
  
  .cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  .cdk-overlay-container, .cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  .cdk-overlay-container {
    position: fixed;
    z-index: 1000;
  }
  
  .cdk-overlay-container:empty {
    display: none;
  }
  
  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
  }
  
  .cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }
  
  .cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
  }
  
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
  }
  
  .cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
  
  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.32);
  }
  
  .cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0;
  }
  
  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
  }
  
  .cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll;
  }
  
  textarea.cdk-textarea-autosize {
    resize: none;
  }
  
  textarea.cdk-textarea-autosize-measuring {
    padding: 2px 0 !important;
    box-sizing: content-box !important;
    height: auto !important;
    overflow: hidden !important;
  }
  
  textarea.cdk-textarea-autosize-measuring-firefox {
    padding: 2px 0 !important;
    box-sizing: content-box !important;
    height: 0 !important;
  }
  
  @keyframes cdk-text-field-autofill-start {
    /*!*/
  }
  
  @keyframes cdk-text-field-autofill-end {
    /*!*/
  }
  
  .cdk-text-field-autofill-monitored:-webkit-autofill {
    animation: cdk-text-field-autofill-start 0s 1ms;
  }
  
  .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    animation: cdk-text-field-autofill-end 0s 1ms;
  }
  
  .mat-focus-indicator {
    position: relative;
  }
  
  .mat-mdc-focus-indicator {
    position: relative;
  }
  
  .mat-ripple-element {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .mat-option {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04);
  }
  
  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04);
  }
  
  .mat-option.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #3f51b5;
  }
  
  .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #ff4081;
  }
  
  .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #f44336;
  }
  
  .mat-optgroup-label {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-optgroup-disabled .mat-optgroup-label {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-pseudo-checkbox {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-pseudo-checkbox::after {
    color: #fafafa;
  }
  
  .mat-pseudo-checkbox-disabled {
    color: #b0b0b0;
  }
  
  .mat-primary .mat-pseudo-checkbox-checked,
  .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #3f51b5;
  }
  
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate,
  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate {
    background: #ff4081;
  }
  
  .mat-warn .mat-pseudo-checkbox-checked,
  .mat-warn .mat-pseudo-checkbox-indeterminate {
    background: #f44336;
  }
  
  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
  .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
    background: #b0b0b0;
  }
  
  .mat-app-background {
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-elevation-z0 {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z2 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z3 {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z4 {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z5 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z6 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z7 {
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z8 {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z9 {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z10 {
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z11 {
    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z12 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z13 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z14 {
    box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z15 {
    box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z16 {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z17 {
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z18 {
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z19 {
    box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z20 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z21 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z22 {
    box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z23 {
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  }
  
  .mat-elevation-z24 {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  
  .mat-theme-loaded-marker {
    display: none;
  }
  
  .mat-autocomplete-panel {
    background: white;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: white;
  }
  
  .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-badge {
    position: relative;
  }
  
  .mat-badge-hidden .mat-badge-content {
    display: none;
  }
  
  .mat-badge-content {
    position: absolute;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    transition: transform 200ms ease-in-out;
    transform: scale(0.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }
  
  .ng-animate-disabled .mat-badge-content,
  .mat-badge-content._mat-animation-noopable {
    transition: none;
  }
  
  .mat-badge-content.mat-badge-active {
    transform: none;
  }
  
  .mat-badge-small .mat-badge-content {
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
  
  .mat-badge-small.mat-badge-above .mat-badge-content {
    top: -8px;
  }
  
  .mat-badge-small.mat-badge-below .mat-badge-content {
    bottom: -8px;
  }
  
  .mat-badge-small.mat-badge-before .mat-badge-content {
    left: -16px;
  }
  
  [dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
    left: auto;
    right: -16px;
  }
  
  .mat-badge-small.mat-badge-after .mat-badge-content {
    right: -16px;
  }
  
  [dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
    right: auto;
    left: -16px;
  }
  
  .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -8px;
  }
  
  [dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: auto;
    right: -8px;
  }
  
  .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -8px;
  }
  
  [dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: auto;
    left: -8px;
  }
  
  .mat-badge-medium .mat-badge-content {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }
  
  .mat-badge-medium.mat-badge-above .mat-badge-content {
    top: -11px;
  }
  
  .mat-badge-medium.mat-badge-below .mat-badge-content {
    bottom: -11px;
  }
  
  .mat-badge-medium.mat-badge-before .mat-badge-content {
    left: -22px;
  }
  
  [dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
    left: auto;
    right: -22px;
  }
  
  .mat-badge-medium.mat-badge-after .mat-badge-content {
    right: -22px;
  }
  
  [dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
    right: auto;
    left: -22px;
  }
  
  .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -11px;
  }
  
  [dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: auto;
    right: -11px;
  }
  
  .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -11px;
  }
  
  [dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: auto;
    left: -11px;
  }
  
  .mat-badge-large .mat-badge-content {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
  
  .mat-badge-large.mat-badge-above .mat-badge-content {
    top: -14px;
  }
  
  .mat-badge-large.mat-badge-below .mat-badge-content {
    bottom: -14px;
  }
  
  .mat-badge-large.mat-badge-before .mat-badge-content {
    left: -28px;
  }
  
  [dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
    left: auto;
    right: -28px;
  }
  
  .mat-badge-large.mat-badge-after .mat-badge-content {
    right: -28px;
  }
  
  [dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
    right: auto;
    left: -28px;
  }
  
  .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -14px;
  }
  
  [dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: auto;
    right: -14px;
  }
  
  .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -14px;
  }
  
  [dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: auto;
    left: -14px;
  }
  
  .mat-badge-content {
    color: white;
    background: #3f51b5;
  }
  
  .cdk-high-contrast-active .mat-badge-content {
    outline: solid 1px;
    border-radius: 0;
  }
  
  .mat-badge-accent .mat-badge-content {
    background: #ff4081;
    color: white;
  }
  
  .mat-badge-warn .mat-badge-content {
    color: white;
    background: #f44336;
  }
  
  .mat-badge-disabled .mat-badge-content {
    background: #b9b9b9;
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-bottom-sheet-container {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    background: white;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-button, .mat-icon-button, .mat-stroked-button {
    color: inherit;
    background: transparent;
  }
  
  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: #3f51b5;
  }
  
  .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
    color: #ff4081;
  }
  
  .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color: #f44336;
  }
  
  .mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
    color: rgba(0, 0, 0, 0.26);
  }
  
  .mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: #3f51b5;
  }
  
  .mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: #ff4081;
  }
  
  .mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: #f44336;
  }
  
  .mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
    background-color: transparent;
  }
  
  .mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
    opacity: 0.1;
    background-color: currentColor;
  }
  
  .mat-button-focus-overlay {
    background: black;
  }
  
  .mat-stroked-button:not(.mat-button-disabled) {
    border-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
    color: rgba(0, 0, 0, 0.87);
    background-color: white;
  }
  
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: white;
  }
  
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    color: white;
  }
  
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    color: white;
  }
  
  .mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
    color: rgba(0, 0, 0, 0.26);
  }
  
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: #3f51b5;
  }
  
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    background-color: #ff4081;
  }
  
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    background-color: #f44336;
  }
  
  .mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
    background-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-raised-button:not([class*=mat-elevation-z]) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  }
  
  .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-button-toggle-standalone,
  .mat-button-toggle-group {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    box-shadow: none;
  }
  
  .mat-button-toggle {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, 0.87);
    background: white;
  }
  
  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
    background-color: black;
  }
  
  .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
  }
  
  [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, 0.12);
  }
  
  .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
    border-left: none;
    border-right: none;
    border-top: solid 1px rgba(0, 0, 0, 0.12);
  }
  
  .mat-button-toggle-checked {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-button-toggle-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: #eeeeee;
  }
  
  .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background: white;
  }
  
  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #bdbdbd;
  }
  
  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    border: solid 1px rgba(0, 0, 0, 0.12);
  }
  
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 48px;
  }
  
  .mat-card {
    background: white;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-card-subtitle {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-checkbox-frame {
    border-color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-checkbox-checkmark {
    fill: #fafafa;
  }
  
  .mat-checkbox-checkmark-path {
    stroke: #fafafa !important;
  }
  
  .mat-checkbox-mixedmark {
    background-color: #fafafa;
  }
  
  .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: #3f51b5;
  }
  
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #ff4081;
  }
  
  .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
    background-color: #f44336;
  }
  
  .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: #b0b0b0;
  }
  
  .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: #b0b0b0;
  }
  
  .mat-checkbox-disabled .mat-checkbox-label {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-checkbox .mat-ripple-element {
    background-color: black;
  }
  
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
    background: #3f51b5;
  }
  
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: #ff4081;
  }
  
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
    background: #f44336;
  }
  
  .mat-chip.mat-standard-chip {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4;
  }
  
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
    opacity: 0.54;
  }
  
  .mat-chip.mat-standard-chip.mat-chip-disabled {
    opacity: 0.4;
  }
  
  .mat-chip.mat-standard-chip::after {
    background: black;
  }
  
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background-color: #3f51b5;
    color: white;
  }
  
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4;
  }
  
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
    background-color: #f44336;
    color: white;
  }
  
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4;
  }
  
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
    background-color: #ff4081;
    color: white;
  }
  
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: white;
    opacity: 0.4;
  }
  
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .mat-table {
    background: white;
  }
  
  .mat-table thead, .mat-table tbody, .mat-table tfoot,
  mat-header-row, mat-row, mat-footer-row,
  [mat-header-row], [mat-row], [mat-footer-row],
  .mat-table-sticky {
    background: inherit;
  }
  
  mat-row, mat-header-row, mat-footer-row,
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-header-cell {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-cell, .mat-footer-cell {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-calendar-arrow {
    fill: rgba(0, 0, 0, 0.54);
  }
  
  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-calendar-table-header-divider::after {
    background: rgba(0, 0, 0, 0.12);
  }
  
  .mat-calendar-table-header,
  .mat-calendar-body-label {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-calendar-body-cell-content,
  .mat-date-range-input-separator {
    color: rgba(0, 0, 0, 0.87);
    border-color: transparent;
  }
  
  .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-form-field-disabled .mat-date-range-input-separator {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-calendar-body-in-preview {
    color: rgba(0, 0, 0, 0.24);
  }
  
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: rgba(0, 0, 0, 0.18);
  }
  
  .mat-calendar-body-in-range::before {
    background: rgba(63, 81, 181, 0.2);
  }
  
  .mat-calendar-body-comparison-identical,
  .mat-calendar-body-in-comparison-range::before {
    background: rgba(249, 171, 0, 0.2);
  }
  
  .mat-calendar-body-comparison-bridge-start::before,
  [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
    background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
  }
  
  .mat-calendar-body-comparison-bridge-end::before,
  [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
    background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
  }
  
  .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
  .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
    background: #a8dab5;
  }
  
  .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
  .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
    background: #46a35e;
  }
  
  .mat-calendar-body-selected {
    background-color: #3f51b5;
    color: white;
  }
  
  .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(63, 81, 181, 0.4);
  }
  
  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white;
  }
  
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
  
  .mat-datepicker-content {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
    background: rgba(255, 64, 129, 0.2);
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
  .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
    background: rgba(249, 171, 0, 0.2);
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
  .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
    background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
  .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
    background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
  .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
    background: #a8dab5;
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
  .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
    background: #46a35e;
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: #ff4081;
    color: white;
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(255, 64, 129, 0.4);
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white;
  }
  
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
    background: rgba(244, 67, 54, 0.2);
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
  .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
    background: rgba(249, 171, 0, 0.2);
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
  .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
    background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
  .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
    background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
  .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
    background: #a8dab5;
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
  .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
    background: #46a35e;
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #f44336;
    color: white;
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(244, 67, 54, 0.4);
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white;
  }
  
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
  
  .mat-datepicker-content-touch {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  
  .mat-datepicker-toggle-active {
    color: #3f51b5;
  }
  
  .mat-datepicker-toggle-active.mat-accent {
    color: #ff4081;
  }
  
  .mat-datepicker-toggle-active.mat-warn {
    color: #f44336;
  }
  
  .mat-date-range-input-inner[disabled] {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-dialog-container {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    background: white;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-divider {
    border-top-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-divider-vertical {
    border-right-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-expansion-panel {
    background: white;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-action-row {
    border-top-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: rgba(0, 0, 0, 0.04);
  }
  
  @media (hover: none) {
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
      background: white;
    }
  }
  
  .mat-expansion-panel-header-title {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-expansion-panel-header[aria-disabled=true] {
    color: rgba(0, 0, 0, 0.26);
  }
  
  .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
  .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
    color: inherit;
  }
  
  .mat-expansion-panel-header {
    height: 48px;
  }
  
  .mat-expansion-panel-header.mat-expanded {
    height: 64px;
  }
  
  .mat-form-field-label {
    color: rgba(0, 0, 0, 0.6);
  }
  
  .mat-hint {
    color: rgba(0, 0, 0, 0.6);
  }
  
  .mat-form-field.mat-focused .mat-form-field-label {
    color: #3f51b5;
  }
  
  .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: #ff4081;
  }
  
  .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #f44336;
  }
  
  .mat-focused .mat-form-field-required-marker {
    color: #ff4081;
  }
  
  .mat-form-field-ripple {
    background-color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #3f51b5;
  }
  
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: #ff4081;
  }
  
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #f44336;
  }
  
  .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
    color: #3f51b5;
  }
  
  .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
    color: #ff4081;
  }
  
  .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
    color: #f44336;
  }
  
  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #f44336;
  }
  
  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #f44336;
  }
  
  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
    background-color: #f44336;
  }
  
  .mat-error {
    color: #f44336;
  }
  
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-form-field-appearance-legacy .mat-hint {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42);
  }
  
  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
    background-size: 4px 100%;
    background-repeat: repeat-x;
  }
  
  .mat-form-field-appearance-standard .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42);
  }
  
  .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
    background-size: 4px 100%;
    background-repeat: repeat-x;
  }
  
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: rgba(0, 0, 0, 0.42);
  }
  
  .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
    background-color: transparent;
  }
  
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #3f51b5;
  }
  
  .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
    color: #ff4081;
  }
  
  .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
    color: #f44336;
  }
  
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #f44336;
  }
  
  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.06);
  }
  
  .mat-icon.mat-primary {
    color: #3f51b5;
  }
  
  .mat-icon.mat-accent {
    color: #ff4081;
  }
  
  .mat-icon.mat-warn {
    color: #f44336;
  }
  
  .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-input-element {
    caret-color: #3f51b5;
  }
  
  .mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42);
  }
  
  .mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42);
  }
  
  .mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42);
  }
  
  .mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42);
  }
  
  .mat-form-field.mat-accent .mat-input-element {
    caret-color: #ff4081;
  }
  
  .mat-form-field.mat-warn .mat-input-element,
  .mat-form-field-invalid .mat-input-element {
    caret-color: #f44336;
  }
  
  .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
    color: #f44336;
  }
  
  .mat-list-base .mat-list-item {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-list-base .mat-list-option {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-list-base .mat-subheader {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-list-item-disabled {
    background-color: #eeeeee;
  }
  
  .mat-list-option:hover, .mat-list-option:focus,
  .mat-nav-list .mat-list-item:hover,
  .mat-nav-list .mat-list-item:focus,
  .mat-action-list .mat-list-item:hover,
  .mat-action-list .mat-list-item:focus {
    background: rgba(0, 0, 0, 0.04);
  }
  
  .mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
    background: rgba(0, 0, 0, 0.12);
  }
  
  .mat-menu-panel {
    background: white;
  }
  
  .mat-menu-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-menu-item {
    background: transparent;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-menu-item[disabled],
  .mat-menu-item[disabled] .mat-menu-submenu-icon,
  .mat-menu-item[disabled] .mat-icon-no-color {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-menu-item .mat-icon-no-color,
  .mat-menu-submenu-icon {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-menu-item:hover:not([disabled]),
  .mat-menu-item.cdk-program-focused:not([disabled]),
  .mat-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-menu-item-highlighted:not([disabled]) {
    background: rgba(0, 0, 0, 0.04);
  }
  
  .mat-paginator {
    background: white;
  }
  
  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-paginator-decrement,
  .mat-paginator-increment {
    border-top: 2px solid rgba(0, 0, 0, 0.54);
    border-right: 2px solid rgba(0, 0, 0, 0.54);
  }
  
  .mat-paginator-first,
  .mat-paginator-last {
    border-top: 2px solid rgba(0, 0, 0, 0.54);
  }
  
  .mat-icon-button[disabled] .mat-paginator-decrement,
  .mat-icon-button[disabled] .mat-paginator-increment,
  .mat-icon-button[disabled] .mat-paginator-first,
  .mat-icon-button[disabled] .mat-paginator-last {
    border-color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-paginator-container {
    min-height: 56px;
  }
  
  .mat-progress-bar-background {
    fill: #cbd0e9;
  }
  
  .mat-progress-bar-buffer {
    background-color: #cbd0e9;
  }
  
  .mat-progress-bar-fill::after {
    background-color: #3f51b5;
  }
  
  .mat-progress-bar.mat-accent .mat-progress-bar-background {
    fill: #fbccdc;
  }
  
  .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
    background-color: #fbccdc;
  }
  
  .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
    background-color: #ff4081;
  }
  
  .mat-progress-bar.mat-warn .mat-progress-bar-background {
    fill: #f9ccc9;
  }
  
  .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
    background-color: #f9ccc9;
  }
  
  .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
    background-color: #f44336;
  }
  
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #3f51b5;
  }
  
  .mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
    stroke: #ff4081;
  }
  
  .mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
    stroke: #f44336;
  }
  
  .mat-radio-outer-circle {
    border-color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #3f51b5;
  }
  
  .mat-radio-button.mat-primary .mat-radio-inner-circle,
  .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: #3f51b5;
  }
  
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #ff4081;
  }
  
  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #ff4081;
  }
  
  .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
    border-color: #f44336;
  }
  
  .mat-radio-button.mat-warn .mat-radio-inner-circle,
  .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
    background-color: #f44336;
  }
  
  .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
  .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
    border-color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
  .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
    background-color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-radio-button .mat-ripple-element {
    background-color: black;
  }
  
  .mat-select-value {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-select-placeholder {
    color: rgba(0, 0, 0, 0.42);
  }
  
  .mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-select-arrow {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-select-panel {
    background: white;
  }
  
  .mat-select-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(0, 0, 0, 0.12);
  }
  
  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: #3f51b5;
  }
  
  .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
    color: #ff4081;
  }
  
  .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
    color: #f44336;
  }
  
  .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
    color: #f44336;
  }
  
  .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-drawer-container {
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-drawer {
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-drawer.mat-drawer-push {
    background-color: white;
  }
  
  .mat-drawer:not(.mat-drawer-side) {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
  
  .mat-drawer-side {
    border-right: solid 1px rgba(0, 0, 0, 0.12);
  }
  
  .mat-drawer-side.mat-drawer-end {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: none;
  }
  
  [dir=rtl] .mat-drawer-side {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: none;
  }
  
  [dir=rtl] .mat-drawer-side.mat-drawer-end {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, 0.12);
  }
  
  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #ff4081;
  }
  
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(255, 64, 129, 0.54);
  }
  
  .mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: #ff4081;
  }
  
  .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: #3f51b5;
  }
  
  .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(63, 81, 181, 0.54);
  }
  
  .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
    background-color: #3f51b5;
  }
  
  .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
    background-color: #f44336;
  }
  
  .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(244, 67, 54, 0.54);
  }
  
  .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
    background-color: #f44336;
  }
  
  .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
    background-color: black;
  }
  
  .mat-slide-toggle-thumb {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: #fafafa;
  }
  
  .mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-slider-track-background {
    background-color: rgba(0, 0, 0, 0.26);
  }
  
  .mat-primary .mat-slider-track-fill,
  .mat-primary .mat-slider-thumb,
  .mat-primary .mat-slider-thumb-label {
    background-color: #3f51b5;
  }
  
  .mat-primary .mat-slider-thumb-label-text {
    color: white;
  }
  
  .mat-primary .mat-slider-focus-ring {
    background-color: rgba(63, 81, 181, 0.2);
  }
  
  .mat-accent .mat-slider-track-fill,
  .mat-accent .mat-slider-thumb,
  .mat-accent .mat-slider-thumb-label {
    background-color: #ff4081;
  }
  
  .mat-accent .mat-slider-thumb-label-text {
    color: white;
  }
  
  .mat-accent .mat-slider-focus-ring {
    background-color: rgba(255, 64, 129, 0.2);
  }
  
  .mat-warn .mat-slider-track-fill,
  .mat-warn .mat-slider-thumb,
  .mat-warn .mat-slider-thumb-label {
    background-color: #f44336;
  }
  
  .mat-warn .mat-slider-thumb-label-text {
    color: white;
  }
  
  .mat-warn .mat-slider-focus-ring {
    background-color: rgba(244, 67, 54, 0.2);
  }
  
  .mat-slider:hover .mat-slider-track-background,
  .mat-slider.cdk-focused .mat-slider-track-background {
    background-color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-slider-disabled .mat-slider-track-background,
  .mat-slider-disabled .mat-slider-track-fill,
  .mat-slider-disabled .mat-slider-thumb {
    background-color: rgba(0, 0, 0, 0.26);
  }
  
  .mat-slider-disabled:hover .mat-slider-track-background {
    background-color: rgba(0, 0, 0, 0.26);
  }
  
  .mat-slider-min-value .mat-slider-focus-ring {
    background-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
  .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
    background-color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
  .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
    background-color: rgba(0, 0, 0, 0.26);
  }
  
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    border-color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }
  
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
    border-color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
    border-color: rgba(0, 0, 0, 0.26);
  }
  
  .mat-slider-has-ticks .mat-slider-wrapper::after {
    border-color: rgba(0, 0, 0, 0.7);
  }
  
  .mat-slider-horizontal .mat-slider-ticks {
    background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
    background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  }
  
  .mat-slider-vertical .mat-slider-ticks {
    background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  }
  
  .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .mat-step-header:hover[aria-disabled=true] {
    cursor: default;
  }
  
  @media (hover: none) {
    .mat-step-header:hover {
      background: none;
    }
  }
  
  .mat-step-header .mat-step-label,
  .mat-step-header .mat-step-optional {
    color: rgba(0, 0, 0, 0.54);
  }
  
  .mat-step-header .mat-step-icon {
    background-color: rgba(0, 0, 0, 0.54);
    color: white;
  }
  
  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: #3f51b5;
    color: white;
  }
  
  .mat-step-header.mat-accent .mat-step-icon {
    color: white;
  }
  
  .mat-step-header.mat-accent .mat-step-icon-selected,
  .mat-step-header.mat-accent .mat-step-icon-state-done,
  .mat-step-header.mat-accent .mat-step-icon-state-edit {
    background-color: #ff4081;
    color: white;
  }
  
  .mat-step-header.mat-warn .mat-step-icon {
    color: white;
  }
  
  .mat-step-header.mat-warn .mat-step-icon-selected,
  .mat-step-header.mat-warn .mat-step-icon-state-done,
  .mat-step-header.mat-warn .mat-step-icon-state-edit {
    background-color: #f44336;
    color: white;
  }
  
  .mat-step-header .mat-step-icon-state-error {
    background-color: transparent;
    color: #f44336;
  }
  
  .mat-step-header .mat-step-label.mat-step-label-active {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-step-header .mat-step-label.mat-step-label-error {
    color: #f44336;
  }
  
  .mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: white;
  }
  
  .mat-stepper-vertical-line::before {
    border-left-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after,
  .mat-stepper-horizontal-line {
    border-top-color: rgba(0, 0, 0, 0.12);
  }
  
  .mat-horizontal-stepper-header {
    height: 72px;
  }
  
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
  .mat-vertical-stepper-header {
    padding: 24px 24px;
  }
  
  .mat-stepper-vertical-line::before {
    top: -16px;
    bottom: -16px;
  }
  
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
    top: 36px;
  }
  
  .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 36px;
  }
  
  .mat-sort-header-arrow {
    color: #757575;
  }
  
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  
  .mat-tab-group-inverted-header .mat-tab-nav-bar,
  .mat-tab-group-inverted-header .mat-tab-header {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
  }
  
  .mat-tab-label, .mat-tab-link {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.38);
  }
  
  .mat-tab-group[class*=mat-background-] > .mat-tab-header,
  .mat-tab-nav-bar[class*=mat-background-] {
    border-bottom: none;
    border-top: none;
  }
  
  .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(197, 202, 233, 0.3);
  }
  
  .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #3f51b5;
  }
  
  .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
    background-color: white;
  }
  
  .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(255, 128, 171, 0.3);
  }
  
  .mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
    background-color: #ff4081;
  }
  
  .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
    background-color: white;
  }
  
  .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(255, 205, 210, 0.3);
  }
  
  .mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
    background-color: #f44336;
  }
  
  .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
    background-color: white;
  }
  
  .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(197, 202, 233, 0.3);
  }
  
  .mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
    background-color: #3f51b5;
  }
  
  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
    color: white;
  }
  
  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
  
  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
  .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
  .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
  .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
  .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
  .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
    border-color: white;
  }
  
  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
  .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
  .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, 0.4);
  }
  
  .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
  .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
  .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
  .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
  .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.12);
  }
  
  .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(255, 128, 171, 0.3);
  }
  
  .mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
    background-color: #ff4081;
  }
  
  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
    color: white;
  }
  
  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
  
  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
  .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
  .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
  .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
  .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
  .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
    border-color: white;
  }
  
  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
  .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
  .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, 0.4);
  }
  
  .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
  .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
  .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
  .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
  .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.12);
  }
  
  .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
  .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(255, 205, 210, 0.3);
  }
  
  .mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
    background-color: #f44336;
  }
  
  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
    color: white;
  }
  
  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
  }
  
  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
  .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
  .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
  .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
  .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
  .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
    border-color: white;
  }
  
  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
  .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
  .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, 0.4);
  }
  
  .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
  .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
  .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
  .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
  .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.12);
  }
  
  .mat-toolbar {
    background: whitesmoke;
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-toolbar.mat-primary {
    background: #3f51b5;
    color: white;
  }
  
  .mat-toolbar.mat-accent {
    background: #ff4081;
    color: white;
  }
  
  .mat-toolbar.mat-warn {
    background: #f44336;
    color: white;
  }
  
  .mat-toolbar .mat-form-field-underline,
  .mat-toolbar .mat-form-field-ripple,
  .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor;
  }
  
  .mat-toolbar .mat-form-field-label,
  .mat-toolbar .mat-focused .mat-form-field-label,
  .mat-toolbar .mat-select-value,
  .mat-toolbar .mat-select-arrow,
  .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit;
  }
  
  .mat-toolbar .mat-input-element {
    caret-color: currentColor;
  }
  
  .mat-toolbar-multiple-rows {
    min-height: 64px;
  }
  
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 64px;
  }
  
  @media (max-width: 599px) {
    .mat-toolbar-multiple-rows {
      min-height: 56px;
    }
  
    .mat-toolbar-row, .mat-toolbar-single-row {
      height: 56px;
    }
  }
  
  .mat-tooltip {
    background: rgba(97, 97, 97, 0.9);
  }
  
  .mat-tree {
    background: white;
  }
  
  .mat-tree-node,
  .mat-nested-tree-node {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .mat-tree-node {
    min-height: 48px;
  }
  
  .mat-snack-bar-container {
    color: rgba(255, 255, 255, 0.7);
    background: #323232;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  
  .mat-simple-snackbar-action {
    color: #ff4081;
  }
  /*!********************************************************************************************************************************************************************************************************!*\
    !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].rules[0].oneOf[1].use[1]!./node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].rules[0].oneOf[1].use[2]!./src/styles.css ***!
    \********************************************************************************************************************************************************************************************************/
  /* You can add global styles to this file, and also import other style files */
  
  html, body { height: 100%; }
  
  body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
  
  mat-icon {
      cursor: pointer;
  }
  
  @media print {
      .modal {
         position: absolute;
         left: 0;
         top: 0;
         margin: 0;
         padding: 0;
         overflow: visible!important;
      }
   }
  
  /*# sourceMappingURL=styles.css.map*/